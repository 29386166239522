import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello Everyone, I am <span className="purple">Edwin Arzu. </span>
            born and raised in <span className="purple"> Bronx, New York.</span>
            <br />
            My academic journey began at the State University of Oneonta, where I laid the groundwork for my understanding of technology with a focus on computer science. However, recognizing my passion for safeguarding digital assets and systems, I decided to transition my degree focus to Cybersecurity and Information Technology at Full Sail University.
            <br />
            I an currently studying Information Technology. I am particularly interested in Cloud Engineering and Security.
            <br />
            <br />
            Apart from coding, some other activities that I enjoy doing!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Creating Music
            </li>
            <li className="about-activity">
              <ImPointRight /> Creating Art
            </li>
          </ul>

          <p style={{ color: "#825542" }}>
            "There is a fine line between Living and being Alive. Everyone is living but, Are you alive?"{" "}
          </p>
          <footer className="blockquote-footer">Edwin Arzu</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
